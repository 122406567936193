<template>
  <g>
     <svg:style>
      .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#handle-gradient);
      }
    </svg:style>
    <linearGradient id="inox-gradient" x1="69.91" y1="275.05" x2="69.91" y2="21.9" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" x1="69.91" y1="146.03" x2="69.91" y2="44.16" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="69.91" y1="252.8" x2="69.91" y2="150.92" xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" x1="11.16" y1="148.48" x2="16.75" y2="148.48" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V27">
      <rect id="inox" 
            v-if="showInox" 
            data-name="inox" 
            class="cls-2" 
            :x="`${doorLeftWidth1 + 55.95}`" 
            :y="`${doorTopHeight + inoxOffset}`" 
            width="27.92" 
            :height="inoxHeight"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 44.16} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 97.54} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 146.03} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 146.03} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 97.54} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 44.16} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 44.16}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 204.31} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 252.8} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 252.8} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 204.31} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 150.92} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 150.92} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 204.31}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  data() {
    return {
      inoxOffset: 21.9
    }
  },
  computed: {
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  },
}
</script>
